import { useState, useEffect } from "react";
import { axiosInstance } from "./AxiosConfiguration";
import { getUserID } from "./AuthBarrier";


export function useFetchVisitResponders(visitID, reload) {
    const [responders, setResponders] = useState(null);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);

    const fetchResponders = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/api/v1/visits/${visitID}/responders`);
            setResponders(response.data);
            setError(null);
        } catch(error) {
            setError(error);
            setResponders(null); 
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchResponders();
    }, [visitID, reload]);

    useEffect(() => {
        if (!loading) {
          console.log('Fetch complete:', responders);
        }
      }, [loading, responders]);

    return { responders, loading, error }
}

export async function tagResponderToVisit(visitID, user_account_id) {
    try {
        const response = await axiosInstance.post(`/api/v1/visits/${visitID}/responders`, {
            "user_account_id": user_account_id,
        })

        return response.status
    } catch(error) {
        console.log(error)
    }
}

export async function removeTaggedResponder(visitID, responder_id) {
    try {
        const response = await axiosInstance.delete(`/api/v1/visits/${visitID}/responders/${responder_id}`)

        return response.status
    } catch(error) {
        console.log(error)
    }
}

export async function getCurrentVisitResponder(visitID) {
    try {
        const response = await axiosInstance.get(`/api/v1/visits/${visitID}/responders`);
        
        for (const responder of response.data) {
            if (responder['user_account_id'] === getUserID()) {
                return responder;
            }
        }

        throw new Error("Couldn't find your responder record");
    } catch(error) {
        console.log(error)
    }
}

export async function approveAllResponders(visitID, responders) {
    try {
        const approvalPromises = responders.map(async (responder) => {
            console.log('Attempting to approve responder:', responder)
            try {
                const response = await axiosInstance.put(`/api/v1/visits/${visitID}/responders/${responder['id']}`, {
                    "status": 'approved',
                    "comment": 'Approved by report creator',
                });
                console.log('Approval response:', response);
                return response;
            } catch (responderError) {
                console.error(`Error approving responder ${responder['id']}:`, responderError);
                // Optionally rethrow or handle individual responder errors
                throw responderError;
            }
        });

        // Wait for all approvals to complete
        const results = await Promise.all(approvalPromises);
        const allApproved = results.every(result => result === true);
        
        return allApproved;
    } catch (error) {
        console.error('Overall approval error:', error);
        throw error; // Rethrow to allow calling function to handle
    }
}